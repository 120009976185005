import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PasswordReset from "../components/PasswordReset"
import { isAuthenticated } from "../utils/Auth"
import { navigate } from "gatsby"

const PasswordResetPage = (props) => {
	isAuthenticated() && navigate('/u/dashboard')

	return (
		<Layout>
			<SEO title="Password Reset" />
			<PasswordReset {...props} />
		</Layout>
	)
}
export default PasswordResetPage
