import React, { Component } from "react"
import axios from "axios"
import { userLogin } from "../../utils/Auth"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import { navigate } from "gatsby"
// @material-ui/icons
import Lock from "@material-ui/icons/Lock"
import Check from "@material-ui/icons/Check"
// core components
import GridContainer from "../Grid/GridContainer.jsx"
import GridItem from "../Grid/GridItem.jsx"
import Button from "../CustomButtons/Button.jsx"
import Card from "../Card/Card.jsx"
import CustomInput from "../CustomInput/CustomInput.jsx"
import InfoArea from "../InfoArea/InfoArea.jsx"
import CardHeader from "../Card/CardHeader"
// styles
import javascriptStyles from "../../assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.jsx"
import withStyles from "@material-ui/core/styles/withStyles"
import { Link } from "gatsby"

const queryString = require("query-string")
const baseUrl = `${process.env.GATSBY_API_URL}`

const style = {
  cardBorder: {
    marginBottom: "30px",
    padding: "20px",
  },
  titleCenter: {
    textAlign: "center",
    padding: "20px",
  },
}

class PasswordReset extends Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      tempPassword: "",
      password: "",
      password2: "",
      success: "",
      error: "",
      redirect: "/u/dashboard",
      agree: false,
      subscribe: true,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
    this.getUrlParams()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  getUrlParams = () => {
    let params = queryString.parse(this.props.location.search)
    const count = Object.keys(params).length

    if (count > 0) {
      for (const [key, value] of Object.entries(params)) {
        this._isMounted &&
          this.setState({
            [key]: value,
          })
      }
    }
  }

  loginCallback = () => {
    setTimeout(() => {
      navigate("/u/dashboard")
    }, 1500)
    this.setState({})
  }

  handleChange(event) {
    const key = event.target.name
    const value = event.target.value

    this.setState({
      [key]: value,
    })
  }

  handleSubmit(event) {
    event.preventDefault()
    let self = this
    const { name, tempPassword, password, password2, agree } = self.state

    if (password !== password2) {
      self.setState({
        success: "",
        error: "Passwords do not match",
      })
      return
    }

    if (!agree) {
      self.setState({
        success: "",
        error:
          "You must agree to the Terms of Use before resetting your password",
      })
      return
    }

    const params = "/user/lost-password-reset?_format=json"
    const payload = {
      name: name,
      temp_pass: tempPassword,
      new_pass: password,
    }
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }

    axios
      .post(baseUrl + params, payload, config)
      .then(function(response) {
        // console.log(response)
        userLogin(name, password, self.loginCallback)
        self._isMounted &&
          self.setState({
            success: "Password Reset successful... Logging you in now :)",
            error: "",
          })
      })
      .catch(function(error) {
        // console.log(error.response)
        let errorResponse = ""
        error.response.status === 500
          ? (errorResponse = error.response.data)
          : (errorResponse = error.response.data.message)
        self.setState({
          success: "",
          error: errorResponse,
        })
      })
  }

  handleToggle() {
    const { agree } = this.state
    this._isMounted && this.setState({ agree: !agree })
  }

  render() {
    const { classes } = this.props
    return (
      <GridContainer
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ padding: "1.5rem 16px" }}
      >
        <Card style={style.cardBorder}>
          <CardHeader
            plain
            color="primary"
            className={`${classes.textCenter} ${classes.cardLoginHeader}`}
          >
            <h5 className={classes.cardTitleWhite}>Reset My Password</h5>
          </CardHeader>
          <GridContainer>
            <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
              <InfoArea
                className={classes.infoArea}
                title="Reset Password"
                description={
                  <p>
                    You're in the homestretch! Go ahead and use the temporary
                    password from the email to create your new permanent
                    password.
                    <br />
                    <br />
                    Keep in mind that the temporary password is only valid for
                    seven (7) days so do this as soon as you can.
                  </p>
                }
                icon={Lock}
                iconColor="info"
              />
            </GridItem>
            <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
              <form onSubmit={this.handleSubmit} className={classes.form}>
                <CustomInput
                  labelText={"Username"}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                    required: true,
                  }}
                  inputProps={{
                    value: this.state.name,
                    onChange: this.handleChange,
                    type: "text",
                    className: "form-control",
                    name: "name",
                    placeholder: "Username...",
                    autoFocus: false,
                  }}
                />

                <CustomInput
                  labelText={"Temporary Password"}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                    required: true,
                  }}
                  inputProps={{
                    name: "tempPassword",
                    value: this.state.tempPassword,
                    onChange: this.handleChange,
                    type: "password",
                    className: "form-control",
                    placeholder: "Temporary Password...",
                    autoFocus: false,
                  }}
                />

                <CustomInput
                  labelText={"Password"}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                    required: true,
                  }}
                  inputProps={{
                    name: "password",
                    value: this.state.password,
                    onChange: this.handleChange,
                    type: "password",
                    className: "form-control",
                    placeholder: "Password...",
                    autoFocus: false,
                  }}
                />
                <CustomInput
                  labelText={"Confirm Password"}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                    required: true,
                  }}
                  inputProps={{
                    name: "password2",
                    value: this.state.password2,
                    onChange: this.handleChange,
                    type: "password",
                    className: "form-control",
                    placeholder: "Confirm Password...",
                    autoFocus: false,
                  }}
                />
                <div className="form-group messages">
                  <p className="success">{this.state.success}</p>
                  <p
                    className="error"
                    dangerouslySetInnerHTML={{ __html: this.state.error }}
                  />
                </div>
                <FormControlLabel
                  classes={{
                    label: classes.label,
                  }}
                  checked={this.state.agree}
                  control={
                    <Checkbox
                      tabIndex={-2}
                      onClick={() => this.handleToggle(1)}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                      required={true}
                    />
                  }
                  label={
                    <span>
                      I have read and agree to the{" "}
                      <Link to={"/terms-of-use"}>Terms of Use</Link>.
                    </span>
                  }
                />
                <div className={classes.textCenter}>
                  <Button
                    type="submit"
                    color="primary"
                    style={{ padding: "10px 50px 20px", marginTop: "30px" }}
                  >
                    RESET PASSWORD NOW!
                  </Button>
                </div>
              </form>
            </GridItem>
          </GridContainer>
        </Card>
      </GridContainer>
    )
  }
}

export default withStyles(javascriptStyles)(PasswordReset)
